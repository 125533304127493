import ampol from "../../../../src/assets/customerStories/ampol.svg";
import daisee from "../../../../src/assets/daisee_logo.svg";
import challenge from "../../../../src/components/CustomerStories/CaseStudies/ampol/challenge-ampol.png";
import solution from "../../../../src/components/CustomerStories/CaseStudies/ampol/solution-ampol.png";
import compliance from "../../../../src/components/CustomerStories/CaseStudies/ampol/compliance-ampol.png";
import { Color } from "../../../../src/components/utils/getColor";
import * as React from 'react';
export default {
  ampol,
  daisee,
  challenge,
  solution,
  compliance,
  Color,
  React
};