import steveTargus from "../../../../src/components/CustomerStories/CaseStudies/myob/steveTargus.jpeg";
import jess from "../../../../src/components/CustomerStories/CaseStudies/myob/jess.jpeg";
import myob from "../../../../src/assets/customerStories/myob.svg";
import daisee from "../../../../src/assets/daisee_logo.svg";
import ui from "../../../../src/assets/ui/11.png";
import challenge from "../../../../src/components/CustomerStories/CaseStudies/myob/challenge.jpg";
import customerSatisfaction from "../../../../src/components/CustomerStories/CaseStudies/myob/csatPerformance.png";
import compliance from "../../../../src/components/CustomerStories/CaseStudies/myob/compliance.png";
import { Color } from "../../../../src/components/utils/getColor";
import * as React from 'react';
export default {
  steveTargus,
  jess,
  myob,
  daisee,
  ui,
  challenge,
  customerSatisfaction,
  compliance,
  Color,
  React
};