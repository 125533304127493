import React from "react";
import CookieConsent from "react-cookie-consent";

export default () => {
    return (
        <CookieConsent
            location=""
            buttonText="I understand"
            cookieName="gatsby-gdpr-google-analytics"
            style={{ 
                background: "#4c2682", 
                bottom: "-92vh", 
                height: "fit-content", 
                flexWrap: "nowrap",
                justifyContent: "center", 
                alignItems: "center", 
                padding: "16px 16% 24px"
            }}
            buttonStyle={{ 
                color: "#fff", 
                fontSize: "0.875rem", 
                background: "linear-gradient(to top,#1bc156,#1ed760)", 
                boxShadow: "rgb(0 0 0 / 2%) 0px 1px 2px",
                textTransform: "uppercase",
                textAlign: "center",
                letterSpacing: "0.8px",
                border: "none",
                borderRadius: "32px",
                padding: "12px 16px",
                fontWeight: "600",
                cursor: "pointer"
            }}
            expires={150}
        >
            Only essential cookies are utilised by the daisee website. Non-essential cookies (e.g. marketing and/or analytics) are not utilised.{" "}
        </CookieConsent>
    );
  };

    