import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { darken } from 'polished'

export enum Color {
  Blue,
  Pink,
  Yellow,
  Purple
}

export default (color: Color | string) => {
  const themeContext = useContext(ThemeContext)
  switch (color) {
    case Color.Blue:
      return `linear-gradient(197.99deg, ${themeContext.colors.blue} 49.47%, ${darken(
        0.1,
        themeContext.colors.blue
      )}  87.67%)`
    case Color.Pink:
      return `linear-gradient(200deg, #fd5288 49.47%, #fd3f7b 87.67%)`
    case Color.Yellow:
      return `linear-gradient(200deg, #F39835 49.47%, #f28d21 87.67%)`
    case Color.Purple:
      return `linear-gradient(200deg, #D381EE 49.47%, #cc6feb 87.67%)`
    default:
      return color
  }
}
