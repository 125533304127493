import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const BaseCSS = createGlobalStyle`
  ${normalize}

  :root {
    color: #212429;
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .headroom-wrapper {
    position: relative;
    z-index: 9999;
  }

  html {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  ul,li {
    list-style-type: none;
  }

  *:focus {
  outline: none; 
  }

  a {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
   }
  }
  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  img { 
    max-width: 100%; 
  }

`;

export default BaseCSS;
