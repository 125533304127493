import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import getGradient from '../utils/getGradient';

interface ButtonProps {
  action: string | ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  display?: 'none' | 'flex';
  btnHeight: string;
  secondary?: boolean;
  bgColor: string;
  onClick?: () => void;
  icon?: JSX.Element;
  iconSide?: 'left' | 'right';
  style?: any
}

const Button: React.FC<ButtonProps> = ({
  action,
  type,
  disabled,
  display,
  btnHeight,
  secondary,
  bgColor,
  onClick,
  icon,
  iconSide,
  style
}) => {
  const showButton = () => {
    if (iconSide && icon) {
      return iconSide === 'left' ? (
        <ButtonAction>
          {icon}
          <Action>{action}</Action>
        </ButtonAction>
      ) : (
        <ButtonAction>
          <Action>{action}</Action>
          {icon}
        </ButtonAction>
      );
    }

    return <Action>{action}</Action>;
  };
  return (
    <StyledButton
      onClick={onClick}
      bgColor={bgColor}
      type={type}
      disabled={disabled}
      display={display}
      btnHeight={btnHeight}
      secondary={secondary}
      style={style}
    >
      <ButtonAction>{showButton()}</ButtonAction>
    </StyledButton>
  );
};

interface Props {
  btnHeight: string;
  secondary?: boolean;
  bgColor: string;
  display?: 'none' | 'flex';
}

const StyledButton = styled.button<Props>`
  background: ${({ secondary, bgColor }) => (secondary ? 'transparent' : getGradient(bgColor))};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.kerning.wider};
  border: ${({ secondary, theme }) => (secondary ? `1px solid ${darken(0.05, theme.colors.green)}` : 'none')};
  border-radius: ${({ theme }) => theme.borderRadius};
  outline: none;
  color: ${({ secondary, theme }) => (secondary ? darken(0.05, theme.colors.green) : 'white')};
  padding-top: ${({ theme }) => theme.spacing.xxs};
  padding-bottom: ${({ theme }) => theme.spacing.xxs};
  padding-left: ${({ theme }) => theme.spacing.xs};
  padding-right: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.fontSize[2]};
  user-select: none;
  height: ${({ btnHeight }) => btnHeight && btnHeight};

  max-height: ${({ btnHeight }) => btnHeight && btnHeight};

  :disabled {
    background: linear-gradient(to top, rgb(200, 200, 200), rgb(213, 213, 213));
  }
  font-weight: 600;

  cursor: pointer;
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
  transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, background 0.1s ease-in-out, color 0.1s ease-in-out;
  height: fit-content;

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const ButtonAction = styled.div`
  white-space: nowrap;
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  width: fit-content;
  align-items: center;
`;

const Action = styled.span`
  white-space: nowrap;
`;

export { Button };
