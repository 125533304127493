import React, { useState, PropsWithChildren } from 'react'

export const LoadingContext = React.createContext<{
  isLoading: boolean
  setIsLoading: (arg0: boolean) => void
}>({
  isLoading: false,
  setIsLoading: () => {}
})

export default ({ children }: PropsWithChildren<{}>) => {
  const [isLoading, setIsLoading] = useState(false)
  return <LoadingContext.Provider value={{ isLoading, setIsLoading }}>{children}</LoadingContext.Provider>
}
