import rotorstudios from './assets/rotorstudios-logo.png'
import beforepay from './assets/beforepay-logo.png'
import douugh from './assets/douugh-logo.png'


export default {
  name: 'Micha Wotton',
  role: 'Chief Technical Officer',
  department: 'CTO',
  pastCompanies: [beforepay, douugh, rotorstudios],
  id: 'micha',
}