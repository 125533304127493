import React from 'react'
import styled, { css } from 'styled-components'
import { StringBreakpoints } from '../../typescript/types'

interface Props {
  padding?: StringBreakpoints
  margin?: string
  fullWidth?: boolean
  hasBorder?: boolean
  id?: string
}

const Container: React.FC<Props> = ({
  children,
  fullWidth,
  hasBorder,
  padding = ['0 1rem', '0 1.5rem', '0 1.5rem'],
  margin = '0 auto',
  id
}) => {
  return (
    <StyledContainer id={id} padding={padding} fullWidth={fullWidth} hasBorder={hasBorder} margin={margin}>
      {children}
    </StyledContainer>
  )
}

const withBorder = css<Props>`
  border-top: ${({ hasBorder, theme }) => hasBorder && `1px solid ${theme.colors.grey300}`};
  border-bottom: ${({ hasBorder, theme }) => hasBorder && `1px solid ${theme.colors.grey300}`};
`

const StyledContainer = styled.div<Props>`
  max-width: ${({ theme, fullWidth }) => (fullWidth ? 'auto' : theme.container.maxWidth)};
  margin: ${({ margin }) => margin};
  flex-grow: 1;
  width: 100%;
  padding: ${({ padding }) => padding![2]};

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: ${({ padding }) => padding![1]};
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    padding: ${({ padding }) => padding![0]};
  }
  ${withBorder}
`

export default Container
