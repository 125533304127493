import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { AlertCircle, X, XOctagon, HelpCircle, CheckCircle } from 'react-feather'
import { motion, AnimatePresence } from 'framer-motion'
import { AlertContext, AlertType } from '../../context/alertContext'

const WrapperTransition = {
  duration: 0.5,
  type: 'tween',
  ease: 'easeInOut'
}

const handleAlertIcon = (alert: AlertType) => {
  switch (alert) {
    case AlertType.SUCCESS:
      return <CheckCircle />
    case AlertType.ERROR:
      return <AlertCircle />
    case AlertType.WARNING:
      return <XOctagon />
    case AlertType.INFO:
      return <HelpCircle />
    default:
      return <AlertCircle />
  }
}

const Alert: React.FC = () => {
  const { alertMessage, setAlertMessage } = useContext(AlertContext)

  return (
    <AnimatePresence exitBeforeEnter>
      {alertMessage.isOpen && (
        <StyledAlertWrapper
          key={AlertType.SUCCESS}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, background: 'rgba(0,0,0,0.25)' }}
          exit={{ opacity: 0 }}
          transition={WrapperTransition}
          onClick={() =>
            setAlertMessage({
              isOpen: false
            })
          }
        >
          <StyledAlert open alertType={alertMessage.alertType}>
            <InnerBox>
              <IconContainer>{alertMessage.alertType && handleAlertIcon(alertMessage.alertType)}</IconContainer>
              <MessageContainer>
                <MessageType>{alertMessage.alertType}</MessageType>
                <MessageText>{alertMessage.message}</MessageText>
              </MessageContainer>
              <IconContainer
                pointer
                onClick={() =>
                  setAlertMessage({
                    isOpen: false
                  })
                }
              >
                <X />
              </IconContainer>
            </InnerBox>
          </StyledAlert>
        </StyledAlertWrapper>
      )}
    </AnimatePresence>
  )
}

interface AlertProps {
  alertType?: AlertType
}

const handleAlertColor = (alert: AlertType) => {
  const themeContext = useContext(ThemeContext)
  switch (alert) {
    case AlertType.SUCCESS:
      return themeContext.colors.green
    case AlertType.ERROR:
      return themeContext.colors.error
    case AlertType.WARNING:
      return themeContext.colors.warning
    case AlertType.INFO:
      return themeContext.colors.info
    default:
      return themeContext.colors.error
  }
}

const StyledAlertWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
`

const StyledAlert = styled.dialog<AlertProps>`
  background: #fff;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  position: relative;
  border-radius: 4px;
  border: none;
  overflow: hidden;
  position: fixed;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.5s ease;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 4px;
    background: ${({ alertType }) => alertType && handleAlertColor(alertType)};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`

const InnerBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.5rem;
  padding: 1.5rem;
`

interface IconProps {
  pointer?: boolean
}

const IconContainer = styled.div<IconProps>`
  display: flex;
  align-items: center;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  &:hover {
    color: ${({ pointer, theme }) => (pointer ? theme.colors.grey500 : 'default')};
  }
`
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const MessageType = styled.strong`
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
`
const MessageText = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey700};
`

export default Alert
