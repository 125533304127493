import React, { useState, PropsWithChildren } from 'react'

export const NavContext = React.createContext<{
  isMenuHidden: boolean
  handleToggleMenu: () => void
}>({
  isMenuHidden: false,
  handleToggleMenu: () => {}
})

export default ({ children }: PropsWithChildren<{}>) => {
  const [isMenuHidden, setIsMenuHidden] = useState(false)
  const handleToggleMenu = () => setIsMenuHidden(!isMenuHidden)
  return <NavContext.Provider value={{ isMenuHidden, handleToggleMenu }}>{children}</NavContext.Provider>
}
