// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-tsx": () => import("./../../../src/pages/ai.tsx" /* webpackChunkName: "component---src-pages-ai-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-daisee-e-book-tsx": () => import("./../../../src/pages/daisee-e-book.tsx" /* webpackChunkName: "component---src-pages-daisee-e-book-tsx" */),
  "component---src-pages-daisee-white-paper-tsx": () => import("./../../../src/pages/daisee-white-paper.tsx" /* webpackChunkName: "component---src-pages-daisee-white-paper-tsx" */),
  "component---src-pages-discovery-tsx": () => import("./../../../src/pages/discovery.tsx" /* webpackChunkName: "component---src-pages-discovery-tsx" */),
  "component---src-pages-discovery-vds-tsx": () => import("./../../../src/pages/discovery_vds.tsx" /* webpackChunkName: "component---src-pages-discovery-vds-tsx" */),
  "component---src-pages-events-dr-claire-ashton-james-index-tsx": () => import("./../../../src/pages/events/dr-claire-ashton-james/index.tsx" /* webpackChunkName: "component---src-pages-events-dr-claire-ashton-james-index-tsx" */),
  "component---src-pages-events-g-summit-index-tsx": () => import("./../../../src/pages/events/g-summit/index.tsx" /* webpackChunkName: "component---src-pages-events-g-summit-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-netlify-forms-roi-tsx": () => import("./../../../src/pages/netlify-forms/roi.tsx" /* webpackChunkName: "component---src-pages-netlify-forms-roi-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-management-changes-index-tsx": () => import("./../../../src/pages/news/management-changes/index.tsx" /* webpackChunkName: "component---src-pages-news-management-changes-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-genesys-landing-index-tsx": () => import("./../../../src/pages/product/genesys/landing/index.tsx" /* webpackChunkName: "component---src-pages-product-genesys-landing-index-tsx" */),
  "component---src-pages-product-genesys-landing-terms-and-conditions-index-tsx": () => import("./../../../src/pages/product/genesys/landing/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-product-genesys-landing-terms-and-conditions-index-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-start-a-demo-tsx": () => import("./../../../src/pages/start-a-demo.tsx" /* webpackChunkName: "component---src-pages-start-a-demo-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-voice-analytics-tsx": () => import("./../../../src/pages/voice-analytics.tsx" /* webpackChunkName: "component---src-pages-voice-analytics-tsx" */),
  "component---src-pages-whitepapers-daisee-compensation-effects-tsx": () => import("./../../../src/pages/whitepapers/daisee-compensation-effects.tsx" /* webpackChunkName: "component---src-pages-whitepapers-daisee-compensation-effects-tsx" */),
  "component---src-pages-whitepapers-daisee-dxc-claims-tsx": () => import("./../../../src/pages/whitepapers/daisee-dxc-claims.tsx" /* webpackChunkName: "component---src-pages-whitepapers-daisee-dxc-claims-tsx" */),
  "component---src-pages-whitepapers-daisee-using-ai-whitepaper-tsx": () => import("./../../../src/pages/whitepapers/daisee-using-ai-whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepapers-daisee-using-ai-whitepaper-tsx" */),
  "component---src-pages-whitepapers-index-tsx": () => import("./../../../src/pages/whitepapers/index.tsx" /* webpackChunkName: "component---src-pages-whitepapers-index-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/CustomerStory.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/Profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/Solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */)
}

