import React from 'react'
import { ThemeProvider } from 'styled-components'
import Header from './src/components/Header/Header'
import AlertContextProvider from './src/context/alertContext'
import LoadingContextProvider from './src/context/loadingContext'
import NavContextProvider from './src/context/navContext'
import Theme from './src/theme/index'
import BaseCSS from './src/theme/BaseCSS'
import Alert from './src/components/Alert'
import './src/styles/fonts.css'

export const wrapRootElement = ({ element }) => {
  return (
    <LoadingContextProvider>
      <ThemeProvider theme={Theme}>
        <NavContextProvider>
          <AlertContextProvider>{element}</AlertContextProvider>
        </NavContextProvider>
      </ThemeProvider>
    </LoadingContextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <BaseCSS />
      <Header {...props} />
      <Alert />
      {element}
    </>
  )
}
