import boronia from './assets/boronia.jpeg';
import clearalts from './assets/clearalts.jpeg';

export default {
  name: 'Dr. David Fussell',
  role: 'Head of Data Science',
  department: 'Data Science',
  pastCompanies: [boronia, clearalts],
  id: 'david',
};
