import {
  AlertTriangle,
  ArrowRightCircle,
  Award,
  Book,
  BookOpen,
  Briefcase,
  CheckSquare,
  Clipboard,
  Cpu,
  DollarSign,
  FileText,
  GitPullRequest,
  HelpCircle,
  Home,
  Icon,
  Image,
  Lock,
  MessageSquare,
  PieChart,
  PlusSquare,
  Pocket,
  Send,
  Share2,
  Sliders,
  Star,
  TrendingUp,
  Users,
  Zap,
} from 'react-feather'

type MenuItemI = {
  label: string
  href: string
  icon?: Icon
}

export type MenuHeadings = 'product' | 'about' | 'AI' | 'solutions' | 'customers' | 'resources'

export type MenuItemStructure = {
  href?: string
  left?: {
    heading: string
    items: MenuItemI[]
  }
  right?: {
    heading: string
    items: MenuItemI[]
  }
  footer?: MenuItemI
}

type MenuItemT = Record<MenuHeadings, MenuItemStructure>

export const menuItems: MenuItemT = {
  product: {
    href: '/product',
    left: {
      heading: 'Product',
      items: [
        {
          label: 'How It Works',
          icon: HelpCircle,
          href: '/product/#how-it-works',
        },
        {
          label: 'Features',
          icon: Cpu,
          href: '/product/#features',
        },
        {
          label: 'daisee Essence',
          icon: Share2,
          href: '/product/#essence',
        },
        {
          label: 'daisee Predictors',
          icon: TrendingUp,
          href: '/product/#predictors',
        },
        {
          label: 'daisee Scorecard Builder',
          icon: Sliders,
          href: '/product/#scbuilder',
        },
        {
          label: 'daisee Report Builder',
          icon: FileText,
          href: '/product/#report',
        },
        {
          label: 'daisee Chat',
          icon: MessageSquare,
          href: '/product/#chat',
        },
      ],
    },
    footer: {
      label: 'Support',
      href: '/contact',
      icon: Users,
    },
  },
  about: {
    href: '/about',
    left: {
      heading: 'daisee',
      items: [
        {
          label: 'Our Story',
          icon: Book,
          href: '/about/#our-story',
        },
        {
          label: 'People',
          icon: Users,
          href: '/about/#our-people',
        },
        {
          label: 'Recognition',
          icon: Award,
          href: '/about/#recognition',
        },
        {
          label: 'Careers',
          icon: Briefcase,
          href: '/careers',
        },
      ],
    },
    right: {
      heading: '',
      items: [
        {
          label: 'Security',
          icon: Lock,
          href: '/about/#security',
        },
      ],
    },
    footer: {
      label: 'Contact daisee',
      href: '/contact',
      icon: Users,
    },
  },
  AI: {
    href: '/ai',
    left: {
      heading: 'Artificial Intelligence',
      items: [
        {
          label: 'Learn About AI',
          icon: GitPullRequest,
          href: '/ai',
        },
      ],
    },
    footer: {
      label: '',
      href: '',
    },
  },
  solutions: {
    href: '/solutions',
    left: {
      heading: 'By Industry',
      items: [
        {
          label: 'Insurance Services',
          icon: Clipboard,
          href: '/solutions/insurance-services',
        },
        {
          label: 'Financial Services',
          icon: DollarSign,
          href: '/solutions/financial-services',
        },
        {
          label: 'Telehealth & Medicine',
          icon: PlusSquare,
          href: '/solutions/telehealth-and-medicine',
        },
        {
          label: 'Debt Collections',
          icon: AlertTriangle,
          href: '/solutions/debt-collections-and-recovery-services',
        },
      ],
    },
    right: {
      heading: 'By Use Case',
      items: [
        {
          label: 'Speech Analytics',
          icon: PieChart,
          href: '/solutions/speech-analytics',
        },
        {
          label: 'Quality Assurance',
          icon: Star,
          href: '/solutions/quality-assurance',
        },
        {
          label: 'Compliance',
          icon: CheckSquare,
          href: '/solutions/contact-centre-compliance',
        },
        {
          label: 'RG 271 Dispute Resolution',
          icon: Pocket,
          href: '/solutions/rg-271-dispute-resolution',
        },
      ],
    },
    footer: {
      label: 'ROI Calculator',
      href: '/#roi',
      icon: Zap,
    },
  },
  customers: {
    href: '/customers',
    left: {
      heading: 'Customer Stories',
      items: [
        {
          label: 'Customer Stories',
          href: '/customers',
        },
        {
          label: 'MYOB Case Study',
          href: '/customers/stories/myob',
        },
        {
          label: 'Employsure Case Study',
          href: '/customers/stories/employsure',
        },
        {
          label: 'Ampol Case Study',
          href: '/customers/stories/ampol',
        },
      ],
    },
    footer: {
      label: 'Hear what our customers have to say',
      href: '/customers',
      icon: ArrowRightCircle,
    },
  },
  resources: {
    left: {
      heading: 'Links',
      items: [
        {
          label: 'daisee Blog',
          icon: Image,
          href: '/blog',
        },
        {
          label: 'Events',
          icon: Home,
          href: '/events',
        },
        {
          label: 'News',
          icon: Send,
          href: '/news',
        },
      ],
    },
    right: {
      heading: 'Content',
      items: [
        {
          label: 'daisee White Papers',
          icon: BookOpen,
          href: '/whitepapers',
        },
        {
          label: 'daisee E-Book',
          icon: BookOpen,
          href: '/daisee-e-book',
        },
      ],
    },
    footer: {
      label: '',
      href: '',
    },
  },
}
