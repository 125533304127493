import google from './assets/google.svg'
import ofx from './assets/ofx.svg'
import firstDirect from './assets/firstDirect.svg'

export default {
  name: 'Richard Kimber',
  department: 'Founder & Director',
  role: 'Founder & Director',
  pastCompanies: [google, ofx, firstDirect],
  id: 'richard'
}
