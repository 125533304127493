import React, { useState, PropsWithChildren } from 'react'

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}
interface AlertProps {
  isOpen: boolean
  message?: string
  alertType?: AlertType
}
const defaultAlert: AlertProps = {
  isOpen: false
}
export const AlertContext = React.createContext<{
  alertMessage: AlertProps
  setAlertMessage: (alertMessage: AlertProps) => void
}>({
  alertMessage: defaultAlert,
  setAlertMessage: () => {}
})
export default ({ children }: PropsWithChildren<{}>) => {
  const [alertMessage, setAlertMessage] = useState<AlertProps>(defaultAlert)
  return <AlertContext.Provider value={{ alertMessage, setAlertMessage }}>{children}</AlertContext.Provider>
}
