import React, { useState, useRef, useContext, useEffect } from 'react'
import styled, { keyframes, css, ThemeContext } from 'styled-components'
import DropMenu from '../Menu/DropMenu'
import Container from '../Container'
import MobileMenu from '../Menu/MobileMenu'
import { Link, PageProps } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import { throttle } from 'lodash'
import { lighten } from 'polished'
import { AnimatePresence } from 'framer-motion'
import { MenuHeadings, menuItems } from '../Menu/menuItems'
import { Logo } from '../Logo/Logo'
import { Button } from '../Button'
import { getObjectKeys } from '../../utils/getObjectKey'
import { globalHistory } from '@reach/router'
import { NavContext } from '../../context/navContext'
import CookieConsent from "../CookieBanner";

export enum NavBg {
  Transparent = 'transparent',
  White = 'white'
}

const Header: React.FC<PageProps> = props => {
  const [navbg, setNavBg] = useState<NavBg>(NavBg.Transparent)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [openMobileMenu, toggleOpenMobileMenu] = useState(false)
  const [initial, setIntial] = useState<boolean>(true)
  const [arrowXPosition, setArrowXPosition] = useState<number>(0)
  const [activeMenu, setActiveMenu] = useState<MenuHeadings>()
  const nav = useRef(null)
  const navContext = useContext(NavContext)
  const { location } = props

  const toggle = () => toggleOpenMobileMenu(prevState => !prevState)

  const handleHover = (e: React.MouseEvent) => {
    const element = e.currentTarget as HTMLLIElement

    const elementOffset = element.offsetLeft + element.getBoundingClientRect().width / 2
    setArrowXPosition(elementOffset)
    if (element instanceof HTMLElement && element.dataset.item !== undefined) {
      return setActiveMenu(element.dataset.item as MenuHeadings)
    }
  }

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else if (window.innerWidth > 768) {
      setIsMobile(false)
      setIntial(true)
    } else if (window.innerWidth > 768) {
      setIsMobile(false)
    }
  }

  const handleScroll = () => (window.pageYOffset === 0 ? setNavBg(NavBg.Transparent) : setNavBg(NavBg.White))

  useEffect(() => {
    if (!isMobile && window.innerWidth <= 768) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', throttle(handleResize, 500))

    return () => window.removeEventListener('scroll', handleResize)
  })

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleScroll, 150))

    return () => window.removeEventListener('scroll', handleScroll)
  })

  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setActiveMenu(undefined)
        toggleOpenMobileMenu(false)
      }
    })
  }, [setActiveMenu])

  return (
    <NavHeader navbg={navbg} onMouseLeave={() => {}}>
      <Container padding={['0', '0', '0']}>
        <NavInner>
          <Logo handleCloseMenu={() => {}} />
          {!navContext.isMenuHidden && (
            <>
              <Nav ref={nav}>
                <NavLinks>
                  {getObjectKeys(menuItems).map(item =>
                    menuItems[item]?.href ? (
                      <StyledLink
                        key={item}
                        to={menuItems[item].href!}
                        activeClassName='active'
                        partiallyActive
                        onMouseEnter={(e: React.MouseEvent) => handleHover(e)}
                        data-item={item}
                      >
                        <NavLink>{item}</NavLink>
                      </StyledLink>
                    ) : (
                      <NavLink key={item} onMouseEnter={(e: React.MouseEvent) => handleHover(e)} data-item={item}>
                        {item}
                      </NavLink>
                    )
                  )}
                </NavLinks>
                <div onMouseLeave={() => setActiveMenu(undefined)}>
                  <CSSTransition in={!!activeMenu} timeout={250} unmountOnExit>
                    {state => <DropMenu arrowXPosition={arrowXPosition} activeMenu={activeMenu} dropState={state} />}
                  </CSSTransition>
                </div>
              </Nav>
              <ButtonGroup>
                {/voice-analytics/.test(location.pathname) ? (
                  <a href='#start-a-demo'>
                    <Button action='start a demo' btnHeight='auto' bgColor={lighten(0.2, themeContext.colors.purple)} />
                  </a>
                ) : (
                  <Link to='/start-a-demo/'>
                    <Button action='start a demo' btnHeight='auto' bgColor={lighten(0.2, themeContext.colors.purple)} />
                  </Link>
                )}
                <Hamburger
                  onClick={() => {
                    toggle()
                    setIntial(false)
                  }}
                  isDropdownOpen={openMobileMenu}
                  initial={initial}
                >
                  <Line navbg={navbg} />
                  <Line navbg={navbg} />
                  <Line navbg={navbg} />
                </Hamburger>
                <AnimatePresence>
                  {openMobileMenu && isMobile && <MobileMenu handleCloseMenu={() => toggle()} />}
                </AnimatePresence>
              </ButtonGroup>
            </>
          )}
        </NavInner>
      </Container>
      <CookieConsent></CookieConsent>
    </NavHeader>
  )
}
interface NavProps {
  navbg: NavBg
}

const NavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const wh = css`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: saturate(180%) blur(5px);
`

const tr = css`
  background: transparent;
  backdrop-filter: none;
`

const getNavBackground = (state: NavBg) => {
  switch (state) {
    default:
    case NavBg.White:
      return wh
    case NavBg.Transparent:
      return tr
  }
}

const NavHeader = styled.header<NavProps>`
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 24px;
  position: fixed;
  z-index: 900;
  will-change: transform;

  ${({ navbg }) => getNavBackground(navbg)}

  :before {
    content: '';
    will-change: background;
    position: absolute;
    height: 100%;
    z-index: -1;
    margin-bottom: -1px;
  }

  :after {
    content: '';
    height: 2px;
    width: 100%;
    background: ${({ theme }) => theme.colors.purple};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
  }

  @media all and (max-width: 48em) {
    padding: 12px 24px;
  }
`

const Nav = styled.nav`
  position: relative;
  ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`

const NavLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grey900};
  text-decoration: none;
  position: relative;

  &.active {
    color: ${({ theme }) => theme.colors.green};
  }

  &.active:after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: -4px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 50%;
    background: ${({ theme }) => theme.colors.green};
  }
`

const NavLink = styled.li`
  padding: 10px 20px;
  line-height: 1;
  cursor: pointer;
  text-transform: capitalize;
  transition: color 0.25s ease;

  &:hover {
    opacity: 0.25;
  }

  @media all and (max-width: 56.25em) {
    font-size: 0.875rem;
    padding: 10px 16px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media all and (max-width: 26.5625em) {
    & > *:first-child {
      display: none;
    }
  }
`

interface HamburgerProps {
  isDropdownOpen: boolean
  initial: boolean
}

const firstOpenKeyframe = keyframes`
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform: translateY(6px) rotate(45deg);
  }
`

const secondOpenKeyframe = keyframes`
  50% {
    transform: translateY(-6px) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
`

const firstCloseKeyFrame = keyframes`
  0% {
    transform:translateY(6px) rotate(45deg);
  }
  100% {
    transform:translateY(0) rotate(0)  ;
  }
`
const secondCloseKeyFrame = keyframes`
  0% {
    transform:translateY(-10px) rotate(-45deg);
  }
  100% {
    transform:translateY(0) rotate(0)  ;
  }
`

const firstOpenAnimation = css`
  animation: 0.3s ease ${firstOpenKeyframe} forwards;
`

const seconOpenAnimation = css`
  animation: 0.3s ease ${secondOpenKeyframe} forwards;
`
const secondCloseAnimation = css`
  animation: 0.3s ease ${secondCloseKeyFrame} forwards;
`

const firstCloseAnimation = css`
  animation: 0.3s ease ${firstCloseKeyFrame} forwards;
`

const Hamburger = styled.div<HamburgerProps>`
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  display: none;
  margin-right: -1.5rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    display: flex;
  }

  transition: opacity 0.3s ease, transform 0.3s ease;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    opacity: 0.3;
  }

  & > span:first-child {
    margin-top: 6px;
  }

  & > span:nth-child(1) {
    ${({ isDropdownOpen, initial }) => !initial && (isDropdownOpen ? firstOpenAnimation : firstCloseAnimation)}
  }
  & > span:nth-child(2) {
    opacity: ${({ isDropdownOpen }) => (isDropdownOpen ? '0' : '1')};
  }
  & > span:nth-child(3) {
    ${({ isDropdownOpen, initial }) => !initial && (isDropdownOpen ? seconOpenAnimation : secondCloseAnimation)}
  }
`

const Line = styled.span<NavProps>`
  background-color: ${({ theme }) => theme.colors.grey900};
  width: 32px;
  height: 2px;
  margin-bottom: 6px;
  transition: transform 0.35s ease, opacity 0.35s ease;
`

export default Header
