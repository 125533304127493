import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { getObjectKeys } from '../../utils/getObjectKey'
import { menuItems } from './menuItems'
import { getMenu } from './DropMenu'

interface Props {
  handleCloseMenu: () => void
}
const MobileMenu: React.FC<PropsWithChildren<{}> & Props> = ({ handleCloseMenu }) => {

  return (
    <StyledMobileMenu
      handleCloseMenu={handleCloseMenu}
      key="mobile-menu"
      initial={{ height: 0, opacity: 0.5, scale: 0, originX: 1, originY: '-48px' }}
      animate={{ height: '80vh', opacity: 1, scale: 1, originX: 0, originY: '0px' }}
      exit={{ height: 0, opacity: 0.5, scale: 0, originX: 1, originY: '-48px' }}
      transition={{ ease: 'easeInOut', type: 'tween', duration: 0.3 }}
    >
      <Wrapper>
      {getObjectKeys(menuItems).map((item) => getMenu(menuItems[item]))}
      </Wrapper>
    </StyledMobileMenu>
  );
};

const StyledMobileMenu = styled(motion.nav)<Props>`
  position: absolute;
  background: #fff;
  top: 100%;
  right: 0;
  border-radius: 8px;
  transition: height 0.3s ease;
  width: 100%;
  box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08), 0 4px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  z-index: 50;
  overflow-y: scroll;
  width: calc(100vw - 3rem);
  &::before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    top: -16px;
    right: 8px;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-radius: 3px 3px 3px 3px;
    pointer-events: none;
    border-color: transparent transparent ${({ theme }) => theme.colors.grey300} transparent;
    transition: left 0.3s ease-in-out;
  }
`;

const Wrapper = styled.div`
  padding: 1.5rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0;
  }
`;

export default MobileMenu;
