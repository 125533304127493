export default {
  colors: {
    black: '#000000',
    grey900: '#212429',
    grey700: '#495057',
    grey500: '#ACB5BD',
    grey400: '#a8b6bd',
    grey300: '#DDE2E5',
    grey200: '#F8F9FA',
    white: '#FFFFFF',
    purple: '#4c2682',
    green: '#1ED760',
    error: '#ff4646',
    warning: '#FFBF1B',
    info: '#3872E1',
    success: '#1ED760',
    pink: '#fd5288',
    blue: '#2E60F4',
    yellow: '#F39835',
    violet: '#D381EE',
    green100: '#f1f7f5',
    yellow100: '#f7f6f1',
    blue100: '#f1f5f7',
    purple100: '#f2f1f7',
    red100: '#f7f1f4',
  },
  borderRadius: '2rem',
  breakpoints: {
    mobile: '@media (max-width: 36em)',
    tablet: '@media (max-width: 48em)',
    desktop: '@media (max-width: 62em)',
  },
  spacing: {
    none: 0,
    px: '1px',
    '4xs': '0.25rem', // 4px
    '3xs': '0.5rem', // 8px
    xxs: '0.75rem', // 12px
    xs: '1rem', // 16px
    s: '1.25rem', // 20px
    md: '1.5rem', // 24px
    lg: '2rem', // 32px
    xl: '2.5rem', // 40px
    xxl: '3rem', // 48px
    '3xl': '4rem', // 64px
    '4xl': '6rem', // 96px
  },
  fontSize: ['0.625rem', '0.75rem', '0.875rem', '1rem', '1.125rem', '1.375rem', '1.625rem', '2rem', '2.5rem', '3rem'],
  kerning: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    none: 1,
    tight: 1.25,
    snug: 1.375,
    normal: 1.5,
    relaxed: 1.625,
  },
  easing: {
    inOutExpo: 'cubic-bezier(0.87, 0, 0.13, 1)',
  },
  transitionDuration: {
    quick: '150ms',
    fast: '250ms',
    normal: '500ms',
    slow: '750ms',
  },
  container: {
    maxWidth: '75em',
  },
  boxShadow: {
    tiny: '0 1px 1px rgba(0,0,0,0.03), 0 1px 1px rgba(0,0,0,0.05)',
    small: '0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.1)',
    medium: '0 5px 12px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.08)',
    large: '0 7px 21px rgba(0,0,0,0.18), 0 5px 5px rgba(0,0,0,0.14)',
  },
};
