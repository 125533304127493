import SydAir from './assets/sydair-logo.png';
import Tabcorp from './assets/tabcorp-logo.png';
import ScentreGroup from './assets/scentre-group-logo.png';


export default {
  name: 'Kyle Bowness',
  role: 'Chief Operating Officer/Chief Financial Officer',
  department: 'COO/CFO',
  pastCompanies: [SydAir, Tabcorp, ScentreGroup],
  id: 'kyle',
};