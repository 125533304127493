import appen from './assets/appen.svg'
import commsecure from './assets/commbank-secure-logo.jpg'

export default {
  name: 'Stephen Norris',
  role: 'Chief Technical Officer',
  department: 'CTO',
  pastCompanies: [appen, commsecure],
  id: 'stephen'
}
