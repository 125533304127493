import React, { PropsWithChildren } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'
import { MenuItemStructure, MenuHeadings, menuItems } from './menuItems'

interface DropMenuProps {
  arrowXPosition?: number
  activeMenu?: MenuHeadings
  dropState: string
}

interface FadeTransitionProps {
  state: TransitionStatus
}

export const getMenu = (item: MenuItemStructure) => {
  const { left, right, footer } = item || {}
  return (
    <Wrapper key={JSON.stringify(item)}>
      <Flex>
        {left && (
          <MenuColumn>
            <MenuHeading>{left.heading}</MenuHeading>
            <MenuGroup>
              {left.items.map(item => (
                <MenuItem key={item.label}>
                  <Link to={item.href}>
                    <MenuInner>
                      {item.icon && <i>{React.createElement(item.icon, { size: 18 })}</i>}
                      <span>{item.label}</span>
                    </MenuInner>
                  </Link>
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuColumn>
        )}
        {right && (
          <MenuColumn>
            <MenuHeading>{right.heading}</MenuHeading>
            <MenuGroup>
              {right.items.map(item => (
                <MenuItem key={item.label}>
                  <Link to={item.href}>
                    <MenuInner>
                      {item.icon && <i>{React.createElement(item.icon, { size: 18 })}</i>}
                      <span>{item.label}</span>
                    </MenuInner>
                  </Link>
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuColumn>
        )}
      </Flex>
      {footer && (
        <MenuFooter>
          <MenuColumn>
            <MenuGroup>
              <MenuItem>
                <Link to={footer.href}>
                  <MenuInner>
                    {footer.icon && <i>{React.createElement(footer.icon, { size: 18 })}</i>}
                    <span>{footer.label}</span>
                  </MenuInner>
                </Link>
              </MenuItem>
            </MenuGroup>
          </MenuColumn>
        </MenuFooter>
      )}
    </Wrapper>
  )
}

const transitionSpeed = 250

const DropMenu: React.FC<PropsWithChildren<{}> & DropMenuProps> = ({ arrowXPosition, activeMenu, dropState }) => {
  return (
    <StyledDropMenu arrowXPosition={arrowXPosition} dropState={dropState} activeMenu={activeMenu}>
      <Container>
        <SwitchTransition mode='out-in'>
          <CSSTransition key={activeMenu} unmountOnExit timeout={transitionSpeed}>
            {state => <FadeDiv state={state}>{activeMenu ? getMenu(menuItems[activeMenu]) : null}</FadeDiv>}
          </CSSTransition>
        </SwitchTransition>
      </Container>
    </StyledDropMenu>
  )
}

const StyledDropMenu = styled.nav<Omit<DropMenuProps, 'setActiveMenu'>>`
  position: absolute;
  width: 100%;
  background: #fff;
  top: 150%;
  left: 0;
  border-radius: 8px;
  box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08), 0 4px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  z-index: 50;
  user-select: none;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: ${({ arrowXPosition }) => `${arrowXPosition}px`};
    top: -1.25rem;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-radius: 3px 3px 3px 3px;
    pointer-events: none;
    border-color: transparent transparent ${({ theme }) => theme.colors.green} transparent;
    transition: left 0.3s ease-in-out;
  }
`

const Container = styled.div`
  overflow: hidden;
`

const Wrapper = styled.div``

export const Flex = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(124px, 1fr));
    gap: 1rem;
    padding: 1.5rem;
  }

  @media all and (max-width: 26.5625em) {
    margin-bottom: 0.25rem;
  }
`

const MenuColumn = styled.div``

const MenuFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.grey200};
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  li {
    color: ${({ theme }) => theme.colors.grey700};
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  }
`

const MenuGroup = styled.ul`
  display: grid;
  gap: 1rem;
  padding-top: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0;
    gap: 0;
  }
`

export const MenuItem = styled.li`
  font-size: 0.875rem;
  width: fit-content;

  svg {
    transition: stroke 0.3s ease;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }

  button {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1rem;
    padding: 1rem 0;
  }

  @media all and (max-width: 26.5625em) {
    font-size: 0.875rem;
    padding: 1rem 0;
    button {
      display: inline-block;
    }
  }
`

export const MenuInner = styled.div`
  display: flex;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
  svg {
    margin-right: 1rem;
    stroke: ${({ theme }) => theme.colors.grey700};
  }

  img {
    margin-right: 1rem;
  }
`

const MenuHeading = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey500};
  padding: 1rem 1rem 0;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 1px;
  min-height: 2.5rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0;
  }
`

const FadeDiv = styled.div<FadeTransitionProps>`
  transition: all 0.3s linear;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export default DropMenu
